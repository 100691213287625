

.survey{
    font-family: Rajdhani;
    color: #FFF;
    display: none;
    
    &.visible{
        display: flex;
    }
    .shadow{
        width: 100%;
        height: 100%;
        position:fixed;
        left:0;
        top:0;
        z-index: 10000;
        background-color: rgba(0, 0, 0, 0.4);
    }
    .modal{
        background: #14151c;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .06);
        position: fixed;
        z-index: 10001;
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 40px);
        max-height: calc(100% - 40px);
        width: max-content;
        height: max-content;
        min-width: 322px;
        min-height: 296px;
        margin: auto;
        top:0;
        bottom:0;
        left:0;
        right:0;
        padding: 16px;
        border-radius: 8px;

        animation: fadeInUp 0.4s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
        .close{
            position: absolute;
            top:10px;
            right:10px;
            cursor: pointer;
            color:#c4c4c4;
            font-size:18px;
            font-family: Rajdhani;
            transform:scaleX(1.5);
        }
        .question{
            font-size: 1rem;
            line-height: 1;
            letter-spacing: -.025em;
            max-width: 80%;
            &.accent{
                font-size: 1.125rem;
                font-weight: 600;
            }
        }
        .thankYou{
            line-height: 1;
            letter-spacing: -.025em;
            font-size: 1.125rem;
            font-weight: 600;
            width: 100%;
            text-align: center;
            margin-top: auto;
            margin-bottom: 0;
        }
        .cta{
            max-width: 200px;
            padding: 4px 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            color: #FFF;
            cursor: pointer;
            background-size: 300% 100%;
            background-image: linear-gradient(123deg, #f36c17 10%, #e23b17 30% 45%, #f38e17 55% 70%, #e26c17);
            margin: 32px auto auto auto;
            font-weight: 700;
            text-transform: uppercase;
        }
        .answer{
            padding: 6px 8px;
            background: #272830;
            border-radius: 4px;
            cursor: pointer;
            transition: all 200ms ease-in-out  ;
            &:hover{
                background: #65666f;
                padding-left: 12px;
            }
        }
    }
}


@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    60% {
        opacity: 1;
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}
